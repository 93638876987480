// Солома

import * as React from 'react';
import { BarChart } from '@mui/x-charts/BarChart';

let district = ['Окт.', 'Благ.', 'Тамб.', 'Иван.', 'Селем.', 'Итого']

let dataZer = [194, 254, 0, 558, 0, 1006]
let dataSoya = [0, 3583, 0, 0, 0, 3583]

const HidingAxis = () => {



  return (
    <BarChart
      series={[{ data:  dataZer}]}
      xAxis={[{ scaleType: 'band', data: district }]}
      height={200}
    //   width={300}
      leftAxis={null}
      // colors={'#777'}
    />
  );
}



const BasicBars = () => {
  return (
    <BarChart
      xAxis={[{ scaleType: 'band', data: district }]}
      series={[{ data: dataZer }, { data: dataSoya }]}
      // width={500}
      height={300}
    />
  );
}

const Charts = () => { 
  return (
  <>
    <HidingAxis/>
    <BasicBars/>
  </>
    

  )
}


export default Charts

// import React, { useState, useEffect } from 'react';

// const YourComponent = () => {
//     const [response, setResponse] = useState(null);
//     const [error, setError] = useState(null);

//     useEffect(() => {
//         const fetchData = async () => {
//             try {
//                 const url = 'https://gis.agrosignal.com/data/reportData';
                
//                 const response = await fetch(url, requestOptions);
//                 const data = await response.json();
//                 console.log(data);
//                 setResponse(data);
//             } catch (error) {
//                 setError(error);
//             }
//         };

//         fetchData();
//     }, []); // Зависимость пуста, чтобы запрос выполнялся только один раз при монтировании компонента

//     return (
//         <div>
//             {response && (
//                 <div>
//                     <h2>Успешный ответ:</h2>
//                     <pre>{JSON.stringify(response, null, 2)}</pre>
//                 </div>
//             )}
//             {error && (
//                 <div>
//                     <h2>Ошибка:</h2>
//                     <pre>{JSON.stringify(error, null, 2)}</pre>
//                 </div>
//             )}
//         </div>
//     );
// };

// export default YourComponent;
