import { React, useEffect } from 'react';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  card: {
    margin: 8,
    backgroundColor: '#121212aa',
    boxShadow: '0 3px 5px 2px rgba(0, 0, 0, 0.1)',
    borderRadius: '8px',
    padding: 12, // Added padding for better visual appearance
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    fontSize: 12,
    fontWeight: 'bold',
    marginLeft: 8, // Added margin for spacing between icon and text
  },
  icon: {
    fontSize: 12,
    width: 36,
    marginRight: 8
  },
  // success: {
  //   color: 'green',
  //   backgroundColor: 'lightgreen',
  // },
  disabled: {
    opacity: 0.5, // Добавляем свойство opacity для снижения прозрачности
    pointerEvents: 'none', // Отключаем взаимодействие с элементом
  },
});

const CustomCard = ({ icon, title, link, status }) => {
  const classes = useStyles();

  const handleClick = () => {
    window.open(link, '_self');
  };

  let statusStyles = {};
  if (status === 'enabled') {
    statusStyles = classes.success;
  } else if (status === 'disabled') {
    statusStyles = classes.disabled;
  }

  return (
    <div onClick={handleClick} className={`${classes.card} ${statusStyles}`}>
      <img src={icon} alt={title} className={classes.icon} />
      <Typography className={classes.title} component="div">
        {title}
      </Typography>
    </div>
  );
};

export default CustomCard;
