import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  animatedIconContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '200px' /* Настройте высоту в зависимости от ваших предпочтений */,
  },
});

const AnimatedIcon = () => {
  const classes = useStyles();
  const [radius, setRadius] = useState(40);

  useEffect(() => {
    const animateIcon = () => {
      const intervalId = setInterval(() => {
        setRadius((prevRadius) => (prevRadius === 40 ? 20 : 40));
      }, 1000);

      return () => clearInterval(intervalId);
    };

    animateIcon();

    // Cleanup function (optional)
    return () => {
      // Perform cleanup (if needed)
    };
  }, []); // Empty dependency array to run the effect only once when the component mounts

  return (
    <div className={classes.animatedIconContainer}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 100 100">
        {/* Иконка (ваша иконка) */}
        <circle cx="50" cy="50" r={radius} fill="blue" />
      </svg>
    </div>
  );
};

export default AnimatedIcon;
