import './App.css';
import GeneralPage from './components/GeneralPage.jsx'
import AnaliticsPage from './components/analitics/AnaiticsPage.jsx'
import Icon from './components/Icons/nav.jsx'


function App() {
  return (
    <div >
      <GeneralPage/>
    </div>
  );
}

export default App;
