import React from 'react';

import Card from './Card'
import AnaliticsPage from './analitics/AnaiticsPage.jsx'


import img from '../images/nav.png'
import nav from '../images/nav.png'
import fuel from '../images/fuel.png'
import stats from '../images/stats.png'
import parsley from '../images/parsley.png'
import wheat from '../images/wheat.png'
import list from '../images/list.png'
import book from '../images/book.png'


import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
    twoColumnCardGrid: {
        display: 'grid',
        gridTemplateColumns: 'repeat(2, 1fr)',
        // gap: 8,
    },
}));

const Service = () => {
    const classes = useStyles();


    return (
        <div style={{ maxWidth: '570px', minWidth: '320px', margin: 'auto', gap: '8px' }}>
            {/* <>log in</> */}
            <Card title="Навигация" icon={nav} link='https://geo.ankmap.ru' />

            <Card title="Агроаналитика" icon={stats} link='http://agro.ankmap.ru/' />
            <div className={classes.twoColumnCardGrid}>
                <Card className={classes.card} title="Карта урожайности" icon={wheat} link='https://ardbot.nextgis.com/resource/93/display?panel=layers' />
                <Card className={classes.card} title="Карта сорняков" icon={parsley} status='disabled' link='/' />
            </div>
            <Card title="Задачи (Канбан)" icon={list} link='http://kb.ankmap.ru/' />
            <Card title="Заправки/Чек" icon={fuel} link='/' status='disabled' />
            <Card title="База знаний" icon={book} link='https://github.com/Ardbot/plan-docs' />
            {/* <AnaliticsPage/> */}
        </div>
    )
}



export default Service